import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  ButtonBase,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import { Close, Visibility, VisibilityOff } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import styles from "./Style.module.css";
import { makeStyles } from "@material-ui/styles";
import CustomTextField from "../../../../../components/FormFields/TextField/TextField.component";
import usePostApproveDialogHook from "./PostApproveDialog.hook";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    color: "blue",
    textDecoration: "underline",
  },
  textField: {
    width: "100%",
  },
  closeBtn: {
    position: "absolute",
    right: "10px",
    top: "10px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PostApproveDialog = ({
  isOpen,
  handleToggle,
  candidateId,
  isInterview,
  handleUpdateApi,
  isEdit,
  renderList
}) => {
  const classes = useStyles();
  const {
    changeTextData,
    errorData,
    form,
    handleSubmit,
    onBlurHandler,
    removeError,
    resData,
    isSubmitted,
    isSubmitting,
    setDeclaration,
    declaration,
  } = usePostApproveDialogHook({
    isOpen,
    handleToggle,
    candidateId,
    isInterview,
    handleUpdateApi,
    isEdit,
    renderList
  });

  return (
    <div>
      <Dialog
        onBackdropClick={() => {}}
        keepMounted
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        open={isOpen}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/*<DialogTitle id="alert-dialog-title">*/}
        <div className={styles.resetPasswordWrapper}>
          <div className={styles.resetWrapper}>
            {/* <div className={styles.upperFlex}>Update Status</div> */}
            <ButtonBase
              classes={{ root: classes.closeBtn }}
              onClick={handleToggle}
            >
              <Close />
            </ButtonBase>
          </div>
          <div className={styles.headingWrapper}>
            <div className={styles.heading}>Approve Document</div>
            <div className={styles.newLine}></div>
            <div className={styles.des}>
            Are you sure you have checked this document and want to approve it?
            </div>
          </div>

          
          
          <div className={styles.printFlex}>
            <div className={styles.icon}>
          <ButtonBase className={styles.edit} onClick={handleToggle}>
              CANCEL
            </ButtonBase>
            <ButtonBase
              onClick={isEdit ? handleUpdateApi : handleSubmit}
              
              className={
               styles.createBtn 
              }
            >
              APPROVE
            </ButtonBase>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PostApproveDialog;

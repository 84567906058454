import { ButtonBase } from "@material-ui/core";
import React from "react";
import historyUtils from "../../../libs/history.utils";
import RouteName from "../../../routes/Route.name";
import styles from "./Style.module.css";
function SuccessResponse({ location }) {
  const type = location?.state?.values;
  return (
    <div className={styles.FormSubmitWrapper}>
      <div className={styles.formSubmitContainer}>
        <div className={styles.formSubmitImageWrapper}>
          <img
            src={require("../../../assets/img/ic_successfully submited.png")}
            className={styles.sky}
          />
        </div>
        <div className={styles.formSubmitBottomWrapper}>
          <div className={styles.submitHeader}>
            <p>Appreciation Recorded!</p>
          </div>
          <div className={styles.submitDescription}>
            <p>
              Your appreciation for <strong>{type}</strong> has been recorded
              successfully.
            </p>
          </div>
          <div>
            <div className={styles.btnContainer}>
              <div className={styles.btnCont1}>
                <ButtonBase
                  type={"button"}
                  onClick={() => {
                    historyUtils.push(RouteName.APPRECIATION_LOGIN);
                  }}
                  className={styles.createBtn}
                >
                  Close
                </ButtonBase>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessResponse;

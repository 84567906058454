import {
  formDataRequest,
  getRequest,
  postRequest,
} from "../libs/AxiosService.util";

export async function serviceCreateDocument(params) {
  return await formDataRequest("countries/create", params);
}

export async function serviceLoginDocument(params) {
  return await postRequest("onboarding/candidate/pre/joining/authenticate", params);
}
export async function serviceLoginPostOnboarding(params) {
  return await postRequest("onboarding/candidate/post/joining/authenticate", params);
}

export async function serviceFetchDocument(params) {
  return await getRequest("onboarding/documents/pre/joining", params);
}
export async function serviceDocumentCreate(params) {
  return await postRequest("onboarding/create/pre/joining", params);
}
export async function serviceDocumentDetail(params) {
  return await postRequest("onboarding/candidate/pre/joining/details", params);
}
export async function serviceDocumentPostDetail(params) {
  return await postRequest("onboarding/candidate/post/joining/details", params);
}
export async function serviceUpdateDocumentFile(params) {
  return await formDataRequest('files/upload', params,{"folder": "document"});
}
export async function serviceDocumentSubmit(params) {
  return await postRequest("onboarding/candidate/pre/joining/submit", params);
}
export async function serviceFetchOnboardingDocument(params) {
  return await getRequest("onboarding/documents/post/joining", params);
}
export async function serviceCreateOnboardingDocument(params) {
  return await postRequest("onboarding/create/post/joining", params);
}

export async function serviceDocumentPostSubmit(params) {
  return await postRequest("onboarding/candidate/post/joining/submit", params);
}

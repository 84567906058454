import { postRequest } from "../libs/AxiosService.util";

export async function serviceAppreciationList(params) {
  return await postRequest("appreciation", params);
}
export async function serviceLoginAppreciation(params) {
  return await postRequest("login", params);
}
export async function serviceAppreciationForm(params) {
  return await postRequest("appreciation/addAppreciation", params);
}
export async function serviceAppreciationDetail(params) {
  return await postRequest("appreciation/detail", params);
}
export async function serviceAppreciationReject(params) {
  return await postRequest("appreciation/reject", params);
}
export async function serviceAppreciationApprove(params) {
  return await postRequest("appreciation/approve", params);
}


import React, { Component, useCallback, useEffect, useMemo } from "react";
import { ButtonBase, IconButton, Menu } from "@material-ui/core";
import classNames from "classnames";
import { connect, useSelector } from "react-redux";
import { InfoOutlined, Telegram } from "@material-ui/icons";
import PageBox from "../../../components/PageBox/PageBox.component";
import styles from "./Style.module.css";
import DataTables from "../../../Datatables/Datatable.table";
import Constants from "../../../config/constants";
import FilterComponent from "../../../components/Filter/Filter.component";
import StatusPill from "../../../components/Status/StatusPill.component";
import useOnboardingDocumentsApprovalList from "./OnboardingDocumentsApprovalList.hook";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import Datatables from "../../../components/Datatables/datatables";
import { removeUnderScore } from "../../../helper/helper";

const OnboardingDocumentsApprovalList = ({}) => {
  const {
    handleSortOrderChange,
    handleRowSize,
    handlePageChange,
    handleFilterDataChange,
    handleSearchValueChange,
    handleDetails,
    isCalling,
    configFilter,
    handleResend,
    handleBankSheetDownload,
    role,
  } = useOnboardingDocumentsApprovalList({});

  const {
    data,
    all: allData,
    currentPage,
    is_fetching: isFetching,
  } = useSelector((state) => state.onboarding);

  const renderStatus = useCallback((status) => {
    return (
      <StatusPill
        status={removeUnderScore(status)}
        style={status === "PROCESSED" && { background: "#ceece2" }}
      />
    );
  }, []);

  const renderFirstCell = useCallback((obj) => {
    if (obj) {
      return (
        <div className={styles.firstCellFlex}>
          <div className={classNames(styles.firstCellInfo, "openSans")}>
            <span className={styles.productName}>
              <strong>{obj?.candidate?.name}</strong>
            </span>
          
            <br />
          </div>
        </div>
      );
    }
    return null;
  }, []);

  const tableStructure = useMemo(() => {
    return [
      {
        key: "name",
        label: "CANDIDATE",
        sortable: false,
        render: (value, all) => <div>{renderFirstCell(all)}</div>,
      },
      {
        key: "contact",
        label: "Contact",
        sortable: false,
        style: { width: "18%" },
        render: (temp, all) => (
          <div>{all?.candidate?.contact}<br/>{all?.candidate?.email}</div>
        ),
      },
      {
        key: "grade",
        label: "RAP ID",
        sortable: false,
        render: (temp, all) => (
          <div className={styles.captialize}>
            {all?.jobOpening?.code} 
          </div>
        ),
      },
      {
        key: "location",
        label: "Location",
        sortable: false,
        render: (temp, all) => (
          <div className={styles.captialize}>
            {all?.jobOpening?.location?.name}
          </div>
        ),
      },
      {
        key: "dept",
        label: "Department",
        sortable: false,
        style: { width: "12%" },
        render: (temp, all) => (
          <div className={styles.captialize}>
            {all?.jobOpening?.department?.name}/
            {all?.jobOpening?.sub_department?.name}
          </div>
        ),
      },
      {
        key: "designation",
        label: "APPLIED FOR",
        sortable: false,
        render: (temp, all) => (
          <div className={styles.captialize}>
            {all?.jobOpening?.designation?.name}
          </div>
        ),
      },
      {
        key: "is_taxation",
        label: "COORDINATOR",
        sortable: false,
        style: { width: "18%" },
        render: (temp, all) => (
          <div>{all?.jobOpening?.assigned_person?.name }</div>
        ),
      },
      
      {
        key: "status",
        label: "STATUS",
        sortable: false,
        render: (temp, all) => <div>{renderStatus(all?.status)}</div>,
      },
     
      {
        key: "cd",
        label: "SUBMITTED ON",
        sortable: false,
        render: (temp, all) => (
          <div className={styles.captialize}>
            {all?.submittedOnText}
          </div>
        ),
      },
      {
        key: "user_id",
        label: "Action",
        render: (temp, all) => (
          <div>
           
              <IconButton
                className={"tableActionBtn"}
                color="secondary"
                disabled={isCalling}
                onClick={() => {
                  handleDetails(all);
                }}
              >
                <InfoOutlined fontSize={"small"} />
              </IconButton>
           
          </div>
        ),
      },
    ];
  }, [renderStatus, renderFirstCell, handleDetails, isCalling]);

  const tableData = useMemo(() => {
    const datatableFunctions = {
      onSortOrderChange: handleSortOrderChange,
      onPageChange: handlePageChange,
      onRowSizeChange: handleRowSize,
    };

    const datatable = {
      ...Constants.DATATABLE_PROPERTIES,
      columns: tableStructure,
      data: data,
      count: allData.length,
      page: currentPage,
    };

    return { datatableFunctions, datatable };
  }, [
    allData,
    tableStructure,
    handleSortOrderChange,
    handlePageChange,
    handleRowSize,
    data,
    currentPage,
  ]);

  return (
    <div>
      <PageBox>
        <div className={styles.headerContainer}>
          <div>
            <span className={styles.title}>Onboarding Documents Approval</span>
            <div className={styles.newLine} />
          </div>
          
        </div>
        <div>        
          <FilterComponent
            is_progress={isFetching}
            filters={configFilter}
            handleSearchValueChange={handleSearchValueChange}
            handleFilterDataChange={handleFilterDataChange}
          />  
        </div>
      </PageBox>
      <Datatables {...tableData.datatable} {...tableData.datatableFunctions} />
    
    </div>
  );
};

export default OnboardingDocumentsApprovalList;

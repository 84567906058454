import { useState } from "react";
import { useEffect, useCallback } from "react";
import { useParams } from "react-router";
import {
  serviceGetOnboardingDetails,
  serviceGetOnboardingApproved,
  serviceGetOnboardingRejected,
} from "../../../services/OnboardingDocumentApproval.service";
import history from "../../../libs/history.utils";
function useOnboardingDocumentsApprovalDetail() {
  const [postOnboardDetail, setPostOnboardDetail] = useState({});
  const [approveDialog, setApproveDialog] = useState(false);
  const [rejectDialog, setRejectDialog] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const { id } = useParams();

  const toggleStatusDialog = useCallback(() => {
    setApproveDialog((e) => !e);
  }, [approveDialog]);
  const handleApproveClick = (docId) => {
    setSelectedDocId(docId);
    toggleStatusDialog();
  };
  const toggleRejectDialog = useCallback(() => {
    setRejectDialog((e) => !e);
  }, [rejectDialog]);
  const handleRejectClick = (docId) => {
    setSelectedDocId(docId);
    toggleRejectDialog();
  };

  useEffect(() => {
    let req = serviceGetOnboardingDetails({ id: id });
    req.then((data) => {
      setPostOnboardDetail(data?.data);
    });
  }, [id]);

  const renderList = useCallback(() => {
    if (id) {
      let req = serviceGetOnboardingDetails({ id: id });
      req.then((data) => {
        setPostOnboardDetail(data?.data);
      });
    }
  }, [id, postOnboardDetail]);

  const handleFeedback = async () => {
    try {
      const response = await serviceGetOnboardingRejected({ id });
      history.goBack();
      console.log("Feedback sent:", response);
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  const handleApproved = async () => {
    try {
      const response = await serviceGetOnboardingApproved({ id });
      history.goBack();
      console.log("Feedback sent:", response);
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  return {
    postOnboardDetail,
    toggleStatusDialog,
    approveDialog,
    toggleRejectDialog,
    rejectDialog,
    handleApproveClick,
    selectedDocId,
    handleRejectClick,
    handleFeedback,
    handleApproved,
    id,
    renderList
  };
}

export default useOnboardingDocumentsApprovalDetail;

import {
    formDataRequest,
    getRequest,
    postRequest,
  } from "../libs/AxiosService.util";
  

  export async function serviceUpdateOnboarding(params) {
    return await postRequest("tax/rebate/review/form/submit", params);
  }
  
  export async function serviceDeleteOnboarding(params) {
    return await postRequest("tax/rebate/review/delete", params);
  }
  export async function serviceGetOnboardingDetails(params) {
    return await postRequest("onboarding/review/post/joining/details", params);
  }
  export async function serviceGetOnboardingList(params) {
    return await postRequest("onboarding/review/post/joining", params);
  }
  export async function serviceGetOnboardingApprove(params) {
    return await postRequest("onboarding/review/post/joining/accept", params);
  }
  export async function serviceGetOnboardingReject(params) {
    return await postRequest("onboarding/review/post/joining/reject", params);
  }
  export async function serviceGetOnboardingApproved(params) {
    return await postRequest("onboarding/review/post/joining/approved", params);
  }
  export async function serviceGetOnboardingRejected(params) {
    return await postRequest("onboarding/review/post/joining/feedback", params);
  }
  export async function serviceExportOnboarding(params) {
    return await postRequest('tax/rebate/excel/report', params);
  }
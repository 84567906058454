import { ButtonBase } from "@material-ui/core";
import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import history from "../../../libs/history.utils";
import styles from "./Style.module.css";
import useAppreciationDetail from "./AppreciationDetail.hook";
import RejectDialog from "./component/RejectDialog/RejectDialog.view";
import AcceptDialog from "./component/AcceptDialog/AcceptDialog.view";

function AppreciationDetail() {
  const {
    data,
    approveDialog,
    toggleRejectDialog,
    rejectDialog,
    toggleStatusDialog,
    id,
  } = useAppreciationDetail({});

  return (
    <div className={styles.container}>
      <RejectDialog
        candidateId={id}
        isOpen={rejectDialog}
        handleToggle={toggleRejectDialog}
      />
      <AcceptDialog
        candidateId={id}
        isOpen={approveDialog}
        handleToggle={toggleStatusDialog}
      />
      <div>
        <ButtonBase onClick={() => history.goBack()}>
          <ArrowBackIosIcon fontSize={"small"} />{" "}
          <span className={"capitalize"}>
            <b>Appreciation Request Detail</b>
          </span>
        </ButtonBase>
        <div className={styles.newLine} />
      </div>
      <div className={styles.plainPaper}>
        <div className={styles.newContainer}>
          <div className={styles.heading}>Appreciation Details</div>
          <div className={styles.commentContainer}></div>
        </div>
        <div className={styles.candidateInfo2}>
          <div className={styles.headerWrap21}>
            <div className={styles.userWrap}>
              <div className={styles.userWrap21}>
                <img
                  src={data?.employee?.image}
                  alt="profile"
                  className={styles.Userimg}
                />
                <div className={styles.imgWrapper}>
                  <div>
                    <b>{data?.employee?.name}</b>
                  </div>
                  <div>{data?.employee?.emp_code}</div>
                </div>
              </div>
            </div>
            <div className={styles.rightWrap}>
              <img
                src={require("../../../assets/img/form_img/ic_appreciated.png")}
                className={styles.Imgwrap}
              />
            </div>
          </div>
          <div className={styles.headerWrap21}>
            <div className={styles.userWrap}>
              <div className={styles.userWrap21}>
                <img
                  src={data?.addedBy?.image}
                  alt="profile"
                  className={styles.Userimg}
                />
                <div className={styles.imgWrapper}>
                  <div>
                    <b>{data?.addedBy?.name}</b>
                  </div>
                  <div>{data?.addedBy?.code}</div>
                </div>
              </div>
            </div>
            <div className={styles.rightWrap}>
              <img
                src={require("../../../assets/img/form_img/ic_appreciated.png")}
                className={styles.Imgwrap}
              />
            </div>
          </div>
        </div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>
            <div className={styles.key}>
              <span className={styles.value}>Category:</span>
              {data?.is_going_the_extra_mile}
            </div>
            <div className={styles.key}>
              <span className={styles.value}>Department:</span>
              {data?.employee?.department}
            </div>
            <div className={styles.key}>
              <span className={styles.value}>Timestamp:</span>
              {data?.createdAtText}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.key}>
              <span className={styles.value}>Location:</span>
              {data?.employee?.location}
            </div>

            <div className={styles.key}>
              <span className={styles.value}>Sub-Department:</span>
              {data?.employee?.sub_department}
            </div>
          </div>
        </div>
        <div className={styles.verti}></div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>
            <div className={styles.key}>
              <span className={styles.value}>Experience:</span>
              {data?.note}
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          data?.status === "APPROVED"
            ? styles.approvedWrapper
            : styles.PdfBtnWrapper
        }
      >
        {data?.status !== "APPROVED" &&
          data?.status !== "ACCOUNTS_APPROVED" && (
            <div className={styles.editBtn2}>
              <ButtonBase className={styles.edit} onClick={toggleRejectDialog}>
                REJECT
              </ButtonBase>
            </div>
          )}

        <div className={styles.btnApproveWrapper}>
          <div>
            <ButtonBase
              // disabled={isSubmitting}
              className={styles.createBtn}
              onClick={toggleStatusDialog}
            >
              {data?.status !== "APPROVED" &&
              data?.status !== "ACCOUNTS_APPROVED"
                ? "APPROVE"
                : "PROCESS"}
            </ButtonBase>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppreciationDetail;

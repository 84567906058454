import React, { useMemo } from "react";
import { ButtonBase } from "@material-ui/core";
import styles from "./style.module.css";
import MultiFile from "../../../../ClaimsManagement/ClaimsDetail/components/ClaimTaxCard/component/FileComponent/FileMultiComponent.component";
import { serviceUpdateDocumentFile } from "../../../../../services/Document.service";
import { removeUnderScore } from "../../../../../helper/helper";
import { Add } from "@material-ui/icons";
import constants from "../../../../../config/constants";

const MultiFieldIncludeFields = ({
  index,
  changeData,
  handlePress,
  data,
  errors,
}) => {
  const handleChange = (e, fieldName, index, subIndex) => {
    if (fieldName) {
      const fd = new FormData();
      fd.append("files", e);
      let req = serviceUpdateDocumentFile(fd);
      req.then((res) => {
        const resData = res?.data;
        console.log("resData", resData);
        changeData(index, subIndex, resData);
      });
    }
  };

  console.log("data", data);

  return (
    <div key={`multi_field_${index}`}>
      <div className={styles.flexContainer}>
        <div className={styles.formWrp}>
          <div className={styles.subHeading}>
            {removeUnderScore(data?.document)}
          </div>
          {data?.files?.map((val, subIndex) => {
            return (
              <>
                {data?.status === "APPROVED" ? (
                  <div>
                    <a
                      key={`uploader_url_${index}`}
                      href={constants?.UPLOADER_URL + val?.files}
                      target="_blank"
                      className={styles.achorImg}
                    >
                      view
                    </a>
                  </div>
                ) : (
                  <div key={`files_${subIndex}`}>
                    <MultiFile
                      // multiple
                      max_size={10 * 1024 * 1024}
                      type={["pdf", "jpeg", "doc", "docx", "jpg", "png"]}
                      fullWidth={true}
                      name="files"
                      label="Upload File"
                      accept={"application/pdf,application/msword,image/*"}
                      error={errors?.[`value_${subIndex}`]}
                      // error={errors?.files}
                      value={val}
                      placeholder={"Upload File"}
                      onChange={(file) => {
                        handleChange(file, "files", index, subIndex);
                      }}
                      // deleteImage={(file) => {
                      //   deleteImage(file, "files", index);
                      // }}
                      removeHideBtn
                    />

                    <div className={styles.firstRow221}>
                      <div className={styles.btnWrap}>
                        <ButtonBase
                          className={styles.removeBtn}
                          // label={this.props.index == 0 ? "+" : '-'}
                          onClick={() => {
                            handlePress(index == 0 ? "-" : "-", index);
                          }}
                        >
                          {index == 0 ? "Remove" : "Remove"}
                        </ButtonBase>
                      </div>
                    </div>
                    {data?.files?.length - 1 === subIndex && (
                      <div className={styles.btnWrapper}>
                        <ButtonBase
                          className={styles.addition}
                          label={"+"}
                          onClick={() => {
                            handlePress("ADDITION", index, subIndex);
                          }}
                        >
                          <Add fontSize={"small"} /> <span>Add More</span>
                        </ButtonBase>
                      </div>
                    )}
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MultiFieldIncludeFields;

import {
    formDataRequest,
    getRequest,
    postRequest,
  } from "../libs/AxiosService.util";
  

  export async function serviceUpdatePreOnboarding(params) {
    return await postRequest("tax/rebate/review/form/submit", params);
  }
  
  export async function serviceDeletePreOnboarding(params) {
    return await postRequest("tax/rebate/review/delete", params);
  }
  export async function serviceGetPreOnboardingDetails(params) {
    return await postRequest("onboarding/review/pre/joining/details", params);
  }
  export async function serviceGetPreOnboardingList(params) {
    return await postRequest("onboarding/review/pre/joining", params);
  }
  export async function serviceGetPreOnboardingApprove(params) {
    return await postRequest("onboarding/review/pre/joining/accept", params);
  }
  export async function serviceGetPreOnboardingReject(params) {
    return await postRequest("onboarding/review/pre/joining/reject", params);
  }
  export async function serviceGetPreOnboardingApproved(params) {
    return await postRequest("onboarding/review/pre/joining/approved", params);
  }
  export async function serviceGetPreOnboardingRejected(params) {
    return await postRequest("onboarding/review/pre/joining/feedback", params);
  }
  export async function serviceExportPreOnboarding(params) {
    return await postRequest('tax/rebate/excel/report', params);
  }
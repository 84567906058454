import { useCallback, useEffect, useState } from "react";
import SnackbarUtils from "../../../libs/SnackbarUtils";
import { serviceGetList } from "../../../services/Common.service";
import { serviceAppreciationForm } from "../../../services/Appreciation.service";
import historyUtils from "../../../libs/history.utils";
import RouteName from "../../../routes/Route.name";
import { useLocation } from "react-router-dom";

const initialForm = {
  employee_id: null,
  note: "",
  isPositivityPowerHouse: false,
  isProblemSolver: false,
  isGoingTheExtraMile: false,
  isTeamWorkChamp: false,
  isLeaderShipLegend: false,
};
const BoolKeys = [
  "isPositivityPowerHouse",
  "isProblemSolver",
  "isGoingTheExtraMile",
  "isTeamWorkChamp",
  "isLeaderShipLegend",
];
const useAppriciationFormHook = () => {
  const [form, setForm] = useState(
    JSON.parse(JSON.stringify({ ...initialForm }))
  );
  const [errorData, setErrorData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [listData, setListData] = useState({
    EMPLOYEES: [],
  });
  const location = useLocation();

  const isAuthentic = location?.state?.empID || false;
  useEffect(() => {
    if (!isAuthentic) {
      historyUtils.push(RouteName.APPRECIATION_LOGIN);
    }
  }, []);

  useEffect(() => {
    serviceGetList(["EMPLOYEES"]).then((res) => {
      if (!res.error) {
        setListData(res.data);
      }
    });
  }, []);

  const removeError = useCallback(
    (title) => {
      const temp = JSON.parse(JSON.stringify(errorData));
      temp[title] = false;
      setErrorData(temp);
    },
    [setErrorData, errorData]
  );

  const changeTextData = useCallback(
    (text, fieldName) => {
      let shouldRemoveError = true;
      const t = { ...form };
      t[fieldName] = text;
      setForm(t);
      shouldRemoveError && removeError(fieldName);
    },
    [removeError, form, setForm]
  );

  const checkFormValidation = useCallback(() => {
    const errors = { ...errorData };
    let required = [];
    required.forEach((val) => {
      if (
        !form?.[val] ||
        (Array.isArray(form?.[val]) && form?.[val].length === 0)
      ) {
        errors[val] = true;
      } else if ([].indexOf(val) < 0) {
        delete errors[val];
      }
    });
    Object.keys(errors).forEach((key) => {
      if (!errors[key]) {
        delete errors[key];
      }
    });
    return errors;
  }, [form, errorData]);

  const submitToServer = useCallback(() => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const updatedKey = {};
      Object.keys(initialForm).forEach((key) => {
        if (BoolKeys.includes(key)) {
          updatedKey[key] = form[key] ? 1 : 0;
        } else if (key === "employee_id") {
          updatedKey[key] = form.employee_id?.id;
        } else {
          updatedKey[key] = form[key];
        }
      });
      serviceAppreciationForm({
        ...updatedKey,
      }).then((res) => {
        if (!res.error) {
          SnackbarUtils.success("Form submitted successfully");
          historyUtils.push(RouteName.APPRECIATION_SUCESS, {
            values: form?.employee_id?.name,
          });
        } else {
          SnackbarUtils.error(res?.message);
        }
        setIsSubmitting(false);
      });
    }
  }, [form, isSubmitting, setIsSubmitting]);

  const handleSubmit = useCallback(async () => {
    const errors = checkFormValidation();
    console.log("===?", form, errors);
    if (Object.keys(errors).length > 0) {
      setErrorData(errors);
      return true;
    }
    submitToServer();
  }, [checkFormValidation, setErrorData, form, submitToServer]);

  const onBlurHandler = useCallback(
    (type) => {
      if (form?.[type]) {
        changeTextData(form?.[type].trim(), type);
      }
    },
    [changeTextData]
  );

  return {
    form,
    changeTextData,
    onBlurHandler,
    removeError,
    handleSubmit,
    errorData,
    isSubmitting,
    listData,
  };
};

export default useAppriciationFormHook;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import RouteName from "../../../routes/Route.name";
import historyUtils from "../../../libs/history.utils";
import {
  serviceDocumentPostDetail,
  serviceDocumentPostSubmit,
  serviceUpdateDocumentFile,
} from "../../../services/Document.service";
import SnackbarUtils from "../../../libs/SnackbarUtils";

function useOnboardingForm() {
  const [candidateInfo, setCandidateInfo] = useState({});
  const [printUpload, setPrintUpload] = useState([]);
  const [singleUpload, setSingleUpload] = useState([]);
  const [errorData, setErrorData] = useState({});
  const [printError, setPrintError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();
  const childRef = useRef(null);

  const isAuthentic = location?.state?.candidateId || false;
  useEffect(() => {
    if (!isAuthentic) {
      historyUtils.push(RouteName.ONBOARDING_LOGIN);
    }
  }, []);

  useEffect(() => {
    if (isAuthentic) {
      let dataValues = serviceDocumentPostDetail({
        candidate_id: isAuthentic,
      });
      dataValues
        .then((data) => {
          const dataInfo = data?.data?.details;
          const documents = dataInfo?.onboardingDocuments;
          setCandidateInfo({ ...dataInfo });
          const printDoc = documents?.filter(
            (val) => val?.type === "DOWNLOAD_PRINT_UPLOAD"
          );
          const singleDoc = documents?.filter(
            (val) =>
              val?.type === "DIRECT_UPLOAD" &&
              val?.upload_requirement === "SINGLE_FILE"
          );
          const multiDoc = documents?.filter(
            (val) =>
              val?.type === "DIRECT_UPLOAD" &&
              val?.upload_requirement === "MULTIPLE_FILE"
          );
          const updatedData = multiDoc?.map((item) => {
            const files = item?.files?.length
              ? item?.files.map((file) => [file])
              : [null];
            return {
              ...item,
              files,
            };
          });
          if (updatedData?.length > 0) {
            childRef?.current?.setData(updatedData);
          }
          setSingleUpload(singleDoc);
          setPrintUpload(printDoc);
        })
        .catch((err) => console.log(err));
    }
  }, [isAuthentic]);

  const removeError = useCallback(
    (type, index) => {
      if (type === "print") {
        setPrintError((prevPrintError) => ({
          ...prevPrintError,
          [`print_${index}`]: false,
        }));
      } else {
        setErrorData((prevErrorData) => ({
          ...prevErrorData,
          [`single_${index}`]: false,
        }));
      }
    },
    [setErrorData, setPrintError]
  );

  const getUrlfromFile = useCallback(
    (text, fieldName, index, type) => {
      const fd = new FormData();
      fd.append("files", text);

      serviceUpdateDocumentFile(fd).then((res) => {
        const data = res?.data;

        const updateState = type === "print" ? setPrintUpload : setSingleUpload;

        updateState((prev) => {
          const updated = [...prev];
          if (updated[index]) {
            updated[index].files = data;
          }
          return updated;
        });
      });
      removeError(type, index);
    },
    [setPrintUpload, setSingleUpload, serviceUpdateDocumentFile]
  );
  const handleDownload = useCallback((url) => {
    window.open(url, "_blank");
  }, []);

  const submitToServer = useCallback(
    (status) => {
      if (!isSubmitting) {
        setIsSubmitting(true);
        const multiData = childRef.current.getData();
        const flattenedData = multiData?.map((item) => ({
          ...item,
          files: (item?.files || [])?.flatMap((file) =>
            Array.isArray(file) ? file : [file]
          ),
        }));
        const payloadData = [
          ...(printUpload || []),
          ...(singleUpload || []),
          ...(flattenedData || []),
        ];
        const data = {
          documents_data: payloadData,
          candidate_id: isAuthentic,
        };
        if (
          !["PENDING", "DRAFTED", "INITIATED"]?.includes(candidateInfo?.status)
        ) {
          data.is_reupload = true;
        }
        if (status) {
          data.is_drafted = true;
        } else {
          data.is_drafted = false;
        }

        let req = serviceDocumentPostSubmit;
        req(data).then((res) => {
          if (!res.error) {
            historyUtils.push(RouteName.ONBOARDING_SUCCESS, {
              isDrafted: status ? true : false,
            });
          } else {
            SnackbarUtils.error(res?.message);
          }
          setIsSubmitting(false);
        });
      }
    },
    [
      printUpload,
      singleUpload,
      isSubmitting,
      setIsSubmitting,
      isAuthentic,
      candidateInfo,
    ]
  );

  const handleDraft = useCallback(
    (status) => {
      submitToServer("is_drafted");
    },
    [setErrorData, printUpload, singleUpload, isAuthentic]
  );
  const checkFormValidation = useCallback(() => {
    setPrintError({});
    const errors = printUpload.reduce((acc, val, index) => {
      if (!val?.files?.length) {
        acc[`print_${index}`] = true;
      }
      return acc;
    }, {});

    return errors;
  }, [printUpload, printError]);

  const checkFormValidationSingle = useCallback(() => {
    setErrorData({});
    const errors = singleUpload.reduce((acc, val, index) => {
      if (!val?.files?.length) {
        acc[`single_${index}`] = true;
      }
      return acc;
    }, {});

    return errors;
  }, [singleUpload, errorData]);

  const handleSubmit = useCallback(async () => {
    const errors = checkFormValidation();
    const errorSingle = checkFormValidationSingle();
    const isChildValid = childRef.current?.isValid?.();
    const hasErrors =
      Object.keys(errors).length > 0 ||
      Object.keys(errorSingle).length > 0 ||
      !isChildValid;

    if (hasErrors) {
      setErrorData(errorSingle);
      setPrintError(errors);
      return true;
    }

    submitToServer();
  }, [
    checkFormValidation,
    checkFormValidationSingle,
    childRef,
    setErrorData,
    setPrintError,
    submitToServer,
  ]);
  console.log(">>>>", printError, errorData);
  return {
    candidateInfo,
    printUpload,
    getUrlfromFile,
    singleUpload,
    childRef,
    handleDraft,
    handleSubmit,
    isSubmitting,
    printError,
    errorData,
    handleDownload,
  };
}

export default useOnboardingForm;

import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useMemo,
} from "react";
import styles from "./style.module.css";
import { useParams } from "react-router";
import MultiFieldIncludeFields from "./MultiFieldIncludeFields.component";
import LogUtils from "../../../../../libs/LogUtils";

const TEMP_OBJ = {
  document: "",
  files: [],
  // child_fees_evidence: null,
};

const MultiFieldIncludeForm = ({ data }, ref) => {
  const [fields, setFields] = useState([JSON.parse(JSON.stringify(TEMP_OBJ))]);
  const [errorData, setErrorData] = useState({});
  const [variants, setVariants] = useState([]);
  const { id } = useParams();
  useImperativeHandle(ref, () => ({
    isValid() {
      return validateData();
    },
    resetData() {
      setFields([JSON.parse(JSON.stringify(TEMP_OBJ))]);
    },
    getData() {
      return fields;
    },
    setData(data) {
      setFields(data);
    },
  }));

  const validateData = (index, type) => {
    const errors = {};
    fields.forEach((val, index) => {
      const err = {};
      if (val?.files) {
        val?.files.forEach((key, subIndex) => {
          if (!key) {
            err["value_" + subIndex] = true;
          }
        });
      }
      if (Object.keys(err)?.length > 0) {
        errors[index] = err;
      }
    });
    setErrorData(errors);
    return !(Object.keys(errors).length > 0);
  };

  useEffect(() => {
    if (data) {
      setFields({ ...data });
    }
  }, [data]);

  const isValid = () => {
    return validateData();
  };

  const removeErrors = useCallback(
    (index, subIndex) => {
      const errors = JSON.parse(JSON.stringify(errorData));
      if (errors[index] && `value_${subIndex}` in errors[index]) {
        errors[index][`value_${subIndex}`] = false;
      }
      setErrorData(errors);

    },
    [setErrorData, errorData]
  );
  const changeData = (index, subIndex, resData) => {
    const tempData = JSON.parse(JSON.stringify(fields));
    const tempArray = tempData[index]?.files;
    const updatedValue = [...tempArray].map((item, index) => {
      if (index === subIndex) {
        return resData;
      }
      return item;
    });
    tempData[index].files = updatedValue;
    LogUtils.log("updated VAlue", tempData);
    setFields([...tempData]);
    removeErrors(index, subIndex);
  };

  const onBlur = useCallback(() => {}, []);

  const handlePress = async (type, index = 0, subIndex) => {
    LogUtils.log("type", type, index);
    const oldState = [...fields];
    if (type == "ADDITION") {
      oldState[index]?.files?.push(null);
      // oldState.push(TEMP_OBJ);
    } else {
      oldState[index]?.files?.splice(subIndex, 1);
    }
    LogUtils.log("oldState", oldState);
    setFields(oldState);
    // validateData();
  };

  const renderFields = useMemo(() => {
    return fields.map((val, index) => {
      const tempFilters = variants.filter((variant) => {
        const index = fields.findIndex((val) => val?.sku?.sku === variant?.sku);
        return index < 0;
      });
      return (
        <div>
          <MultiFieldIncludeFields
            variants={tempFilters}
            validateData={validateData}
            errors={index in errorData ? errorData[index] : null}
            changeData={changeData}
            handlePress={handlePress}
            data={val}
            index={index}
            onBlur={onBlur}
          />
        </div>
      );
    });
  }, [
    variants,
    errorData,
    setErrorData,
    validateData,
    changeData,
    handlePress,
    onBlur,
    fields,
  ]);

  return <>{renderFields}</>;
};

export default forwardRef(MultiFieldIncludeForm);

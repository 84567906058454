import React from "react";
import useOnboardingDocumentsApprovalDetail from "./OnboardingDocumentsApprovalDetail.hook";
import styles from "./Style.module.css";
import history from "../../../libs/history.utils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ButtonBase, IconButton, MenuItem } from "@material-ui/core";
//import TaxData from "./component/TaxData/TaxData";
import PostApproveDialog from "./components/ApproveDialog/PostApproveDialog.view";
import PostRejectDialog from "./components/RejectDialog/PostRejectDialog.view";
import image from "../../../assets/img/download.png";
import StatusPill from "../../../components/Status/StatusPill.component";
import { removeUnderScore } from "../../../helper/helper";
import constants from "../../../config/constants";
import { useSelector } from "react-redux";
import {
  AssignmentOutlined,
  CloseOutlined,
  DoneAllOutlined,
} from "@material-ui/icons";
function OnboardingDocumentsApprovalDetail() {
  const {
    employeeDetail,
    postOnboardDetail,
    toggleStatusDialog,
    approveDialog,
    toggleRejectDialog,
    handleApproveClick,
    handleRejectClick,
    rejectDialog,
    selectedDocId,
    handleFeedback,
    handleApproved,
    id,
    renderList
  } = useOnboardingDocumentsApprovalDetail({});
  const { role } = useSelector((state) => state.auth);
  const formatDocumentName = (name) => {
    if (!name || typeof name !== "string") return "N/A";
    return name
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const allApproved = postOnboardDetail?.onboardingDocuments?.every(
    (doc) => doc.status === "APPROVED"
  );
  const hasRejected = postOnboardDetail?.onboardingDocuments?.some(
    (doc) => doc.status === "REJECTED"
  );
  const hasPending = postOnboardDetail?.onboardingDocuments?.some(
    (doc) => doc.status === "PENDING"
  );
  return (
    <div>
      {/* {console.log("doc", candidateId)} */}
      <div className={styles.outerFlex}>
        <div>
          <ButtonBase onClick={() => history.goBack()}>
            <ArrowBackIosIcon fontSize={"small"} />{" "}
            <span className={"capitalize"}>
              <b>Onboarding Documents Detail</b>
            </span>
          </ButtonBase>
          <div className={styles.newLine} />
        </div>
      </div>
      <div className={styles.plainPaper}>
        <div className={styles.newContainer}>
          <div className={styles.editFlex}>
            <div className={styles.heading}>Candidate Information</div>

            <StatusPill status={removeUnderScore(postOnboardDetail?.status)} />
          </div>

          <div className={styles.mainFlex}>
            <div className={styles.left221}>
              <div className={styles.imageContainer}>
                <img
                  className={styles.claimimg}
                  src={
                    postOnboardDetail?.image ? postOnboardDetail?.image : image
                  }
                />
              </div>
              <div className={styles.namepreOnboardDetail}>
                <div className={styles.key}>
                  <span className={styles.value}>Name:</span>
                  {postOnboardDetail?.candidate?.name}
                </div>

                <div className={styles.key}>
                  <span className={styles.value}>Location:</span>
                  {postOnboardDetail?.jobOpening?.location?.name}
                </div>
                <div className={styles.key}>
                  <span className={styles.value}>Applied For:</span>
                  {postOnboardDetail?.jobOpening?.designation?.name}
                </div>
              </div>
            </div>

            <div className={styles.left}>
              <div className={styles.nameHigher}>
                <div className={styles.key}>
                  <span className={styles.value}>RAP ID:</span>
                  <span>{postOnboardDetail?.jobOpening?.code}</span>
                </div>

                <div className={styles.key}>
                  <span className={styles.value}>Department:</span>
                  <span>{postOnboardDetail?.jobOpening?.department?.name}</span>
                </div>
                <div className={styles.key}>
                  <span className={styles.value}>Submitted On:</span>
                  <span>{postOnboardDetail?.submittedOnText}</span>
                </div>
              </div>

              {/* <div className={styles.verticalLine}></div> */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.plainPaper}>
        <div className={styles.newContainer}>
          <div className={styles.heading}>Documents</div>

          {postOnboardDetail?.onboardingDocuments?.length > 0 ? (
            postOnboardDetail.onboardingDocuments.map((doc, index) => (
              <div key={doc._id} className={styles.documentContainer}>
                <div className={styles.editFlex}>
                  <div className={styles.heading}>
                    {formatDocumentName(doc.document)}
                  </div>
                  {doc?.status === "PENDING" && (
                    <div className={styles.icon}>
                      <IconButton
                        className={"tableActionBtnSuccess"}
                        color="secondary"
                        onClick={() => handleApproveClick(doc._id)}
                      >
                        <DoneAllOutlined
                          fontSize={"small"}
                          className={styles.openIconResponse}
                        />{" "}
                        <span className={styles.subTextResponse}>ACCEPT</span>
                      </IconButton>
                      <IconButton
                        className={"tableActionBtnError"}
                        color="secondary"
                        onClick={() => handleRejectClick(doc._id)}
                      >
                        <CloseOutlined
                          fontSize={"small"}
                          className={styles.openIcon}
                        />{" "}
                        <span className={styles.subText}>REJECT</span>
                      </IconButton>
                    </div>
                  )}
                  {doc?.status != "PENDING" && (
                    <StatusPill status={removeUnderScore(doc?.status)} />
                  )}
                </div>
                <div className={styles.mainFlex}>
                  <div className={styles.left221}>
                    <div className={styles.namepreOnboardDetail}>
                      <div className={styles.key}>
                        <span className={styles.value}>Type:</span>
                        {formatDocumentName(doc.type)}
                      </div>
                      <div className={styles.key}>
                        <span className={styles.value}>Sample Template:</span>
                        {doc.sample_example ? (
                          <a
                            href={doc.sample_example}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.hyperlinkText}
                          >
                            View Template
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </div>
                      <div className={styles.key}>
                        <span className={styles.value}>
                          Upload Requirement:
                        </span>
                        {formatDocumentName(doc.upload_requirement)}
                      </div>
                      <div className={styles.key}>
                        <span className={styles.value}>Uploaded File:</span>
                        {doc.files && doc.files.length > 0
                          ? doc.files.map((file, index) => (
                              <span key={index}>
                                <a
                                  href={constants?.UPLOADER_URL+file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={styles.hyperlinkText}
                                >
                                  View File
                                </a>
                                {index < doc.files.length - 1 && ", "}
                              </span>
                            ))
                          : "N/A"}
                      </div>
                      <div className={styles.key}>
                        {doc.rejection_reason &&
                          doc.rejection_reason.trim() !== "" && (
                            <>
                              <span className={styles.value}>
                                Reason for Rejection:
                              </span>
                              {formatDocumentName(doc.rejection_reason)}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                {index !== postOnboardDetail.onboardingDocuments.length - 1 && (
                  <div className={styles.verticalLine}></div>
                )}
                <PostApproveDialog
                  candidateId={selectedDocId}
                  isOpen={approveDialog}
                  handleToggle={toggleStatusDialog}
                  renderList={renderList}
                />
                <PostRejectDialog
                  candidateId={selectedDocId}
                  isOpen={rejectDialog}
                  handleToggle={toggleRejectDialog}
                  renderList={renderList}
                />
              </div>
            ))
          ) : (
            <div className={styles.noData}>No Documents Available</div>
          )}
        </div>
      </div>
      {postOnboardDetail?.status != "PENDING" && (
        <div className={styles.plainPaper}>
          <div className={styles.newContainer}>
            <div className={styles.heading}>Remarks</div>

            <div className={styles.documentContainer}>
              <div className={styles.status}>
                {
                  <StatusPill
                    status={removeUnderScore(postOnboardDetail?.status)}
                    style={{ border: "none", backgroundColor: "transparent" }}
                  />
                }
              </div>
              <div className={styles.heading}>
                {postOnboardDetail?.approvedBy?.name}{" "}
                {postOnboardDetail?.approvedBy?.code &&
                  `(${postOnboardDetail?.approvedBy?.code})`}
                {(postOnboardDetail?.approvedBy?.name ||
                  postOnboardDetail?.approvedBy?.code) &&
                  " | "}
                {postOnboardDetail?.approvedOnText}
              </div>
            </div>
          </div>
        </div>
      )}
      {postOnboardDetail?.status === "PENDING" && (
        <>
          {hasRejected && !hasPending && (
            <div className={styles.PdfBtnWrapper}>
              <div className={styles.editBtn2}></div>

              <div className={styles.btnApproveWrapper}>
                <div>
                  <ButtonBase
                    className={styles.createBtn}
                    onClick={handleFeedback}
                  >
                    SEND FEEDBACK
                  </ButtonBase>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {postOnboardDetail?.status === "PENDING" && (
        <>
          {allApproved && !hasPending && !hasRejected && (
            <div className={styles.PdfBtnWrapper}>
              <div className={styles.editBtn2}></div>

              <div className={styles.btnApproveWrapper}>
                <div>
                  <ButtonBase
                    className={styles.createBtn}
                    onClick={handleApproved}
                  >
                    SHARE APPROVAL EMAIL
                  </ButtonBase>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default OnboardingDocumentsApprovalDetail;

import { ButtonBase, CircularProgress } from "@material-ui/core";
import React from "react";
import useAppriciationFormHook from "./AppriciationForm.hook";
import styles from "./Style.module.css";
import SnackbarComponent from "../../../components/Snackbar.component";
import CustomTextField from "../../../components/FormFields/TextField/TextField.component";
import images from "./AppreciationImages";
import CustomAutoComplete from "../../../components/FormFields/AutoCompleteText/CustomAutoComplete";

function AppriciationForm() {
  const {
    form,
    changeTextData,
    handleSubmit,
    errorData,
    isSubmitting,
    listData,
  } = useAppriciationFormHook({});
  return (
    <div className={styles.AppriciationFormWrapper}>
      <div>
        <div className={styles.logoImg}>
          <img
            src={require("../../../assets/img/form_img/skytech_login_logo@2x.png")}
            className={styles.sky}
          />
        </div>
        <div className={styles.loginSignupText}>
          <h1 className={styles.headingText}>
            Celebrate Wins & Give Recognition Where It’s Due
          </h1>
        </div>

        <div className={styles.evaluationContainer}>
          <div className={styles.candidateInfo}>
            Take a moment to recognize the hard work, creativity, or kindness of
            a colleague who made a difference. A small appreciation can go a
            long way in building stronger connections and a positive work
            culture.
          </div>
          <div className={styles.candidateInfo2}>
            {form?.employee_id?.id ? (
              <div className={styles.headerWrap21}>
                <div className={styles.left}>
                  <div className={styles.userWrap}>
                    <div className={styles.userWrap21}>
                      <img
                        src={form?.employee_id?.image}
                        alt="profile"
                        className={styles.Userimg}
                      />
                      <div className={styles.imgWrapper}>
                        <div>
                          <b>{form?.employee_id?.name}</b>
                        </div>
                        <div>{form?.employee_id?.code}</div>
                      </div>
                    </div>
                    <div>
                      <div>{form?.employee_id?.grade_name}</div>
                      <div>{form?.employee_id?.cadre}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.right}>
                  <img
                    src={require("../../../assets/img/form_img/ic_badge.png")}
                    className={styles.Imgwrap}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.headWrap}>
                <img
                  src={require("../../../assets/img/form_img/ic_badge.png")}
                  className={styles.Imgwrap}
                />
                <div className={styles.desWrap}>
                  Appreciate a Colleague Today! Every win, big or small,
                  deserves recognition.
                </div>
              </div>
            )}
          </div>
          <div className={styles.candidateInfoContainer2}>
            <div className={styles.rankingWrapper}>
              <span className={styles.heading}>
                What made your colleague stand out?
              </span>
              <div className={styles.headingDes}>
                <span>
                  Choose one reason from the below listed categories to help
                  express your appreciation.
                </span>
              </div>
            </div>
            <div className={"formFlex"}>
              <div className={"formGroup"}>
                <CustomAutoComplete
                  autoCompleteProps={{
                    freeSolo: false,
                    getOptionLabel: (option) => option?.label,
                  }}
                  dataset={listData?.EMPLOYEES}
                  datasetKey={"label"}
                  onTextChange={(text) => {
                    changeTextData(text, "employee_id");
                  }}
                  variant={"outlined"}
                  label={"Who do you want to appreciate?"}
                  name={"employee_id"}
                  isError={errorData?.employee_id}
                  value={form?.employee_id}
                />
              </div>
            </div>
            <div className={styles.cont}>
              <div
                className={styles.emojCard}
                onClick={(e) =>
                  changeTextData(
                    !form?.isPositivityPowerHouse,
                    "isPositivityPowerHouse"
                  )
                }
              >
                <img
                  src={
                    form?.isPositivityPowerHouse
                      ? images?.positive.active
                      : images?.positive.inactive
                  }
                />
                <span className={styles.performanceindex}>
                  Positivity Powerhouse
                </span>
              </div>
              <div
                className={styles.emojCard}
                onClick={(e) =>
                  changeTextData(!form?.isProblemSolver, "isProblemSolver")
                }
              >
                <img
                  src={
                    form?.isProblemSolver
                      ? images?.problem?.active
                      : images?.problem?.inactive
                  }
                />
                <span className={styles.performanceindex}>Problem Solver</span>
              </div>
              <div
                className={styles.emojCard}
                onClick={(e) =>
                  changeTextData(!form?.isTeamWorkChamp, "isTeamWorkChamp")
                }
              >
                <img
                  src={
                    form?.isTeamWorkChamp
                      ? images?.champ?.active
                      : images?.champ?.inactive
                  }
                />
                <span className={styles.performanceindex}>Teamwork Champ</span>
              </div>
              <div
                className={styles.emojCard}
                onClick={(e) =>
                  changeTextData(
                    !form?.isGoingTheExtraMile,
                    "isGoingTheExtraMile"
                  )
                }
              >
                <img
                  src={
                    form?.isGoingTheExtraMile
                      ? images?.extra_mile?.active
                      : images?.extra_mile?.inactive
                  }
                />
                <span className={styles.performanceindex}>
                  Going the Extra Mile
                </span>
              </div>{" "}
              <div
                className={styles.emojCard}
                onClick={(e) =>
                  changeTextData(
                    !form?.isLeaderShipLegend,
                    "isLeaderShipLegend"
                  )
                }
              >
                <img
                  src={
                    form?.isLeaderShipLegend
                      ? images?.legend?.active
                      : images?.legend?.inactive
                  }
                />
                <span className={styles.performanceindex}>
                  Leadership Legend
                </span>
              </div>
            </div>

            <div className={"formFlex"}>
              <div className={"formGroup"}>
                <CustomTextField
                  isError={errorData?.note}
                  errorText={errorData?.note}
                  label={"Share your experience"}
                  value={form?.note}
                  onTextChange={(text) => {
                    changeTextData(text, "note");
                  }}
                  multiline
                  rows={3}
                />
              </div>
            </div>
          </div>
          <div className={styles.btnContainer}>
            <div className={styles.btnCont1}>
              <ButtonBase
                disabled={isSubmitting}
                type={"button"}
                onClick={handleSubmit}
                className={styles.createBtn}
              >
                {isSubmitting ? (
                  <CircularProgress color="success" size="20px" />
                ) : (
                  "Submit"
                )}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
      <SnackbarComponent />
    </div>
  );
}

export default AppriciationForm;

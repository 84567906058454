import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import historyUtils from "../../../libs/history.utils";
import { serviceGetList } from "../../../services/Common.service";
import RouteName from "../../../routes/Route.name";
import {
  actionCreateContact,
  actionFetchContact,
  actionSetPageContactRequests,
  actionUpdateContact,
} from "../../../actions/ContactList.action";

const useContactList = () => {
  const [status, setStatus] = useState("");
  const [isCalling, setIsCalling] = useState(false);
  const [editData, setEditData] = useState(null);
  const dispatch = useDispatch();
  const [listData, setListData] = useState({
    LOCATIONS: [],
    GRADES: [],
    DEPARTMENTS: [],
  });
  const isMountRef = useRef(false);
  const {
    sorting_data: sortingData,
    is_fetching: isFetching,
    query,
    query_data: queryData,
  } = useSelector((state) => state.contact);

  const statusActive = {
    label: "Status",
    name: "status",
    type: "select",
    value: "ACTIVE",
  };

  const renderStatus = useCallback(
    (val) => {
      if (Array.isArray(val) && val?.length > 0) {
        return [...val, statusActive];
      } else {
        return [statusActive];
      }
    },
    [statusActive]
  );

  console.log("queryData", queryData);
  const initData = useCallback(() => {
    dispatch(
      actionFetchContact(1, sortingData, {
        query: isMountRef.current ? query : null,
        query_data: isMountRef.current ? renderStatus(queryData) : renderStatus([]),
      })
    );
  }, [renderStatus,renderStatus]);

  useEffect(() => {
    initData();
    isMountRef.current = true;
    serviceGetList(["LOCATIONS", "GRADES", "DEPARTMENTS"]).then((res) => {
      if (!res.error) {
        setListData(res.data);
      }
    });
  }, []);

  const handlePageChange = useCallback((type) => {
    console.log("_handlePageChange", type);
    dispatch(actionSetPageContactRequests(type));
  }, []);
  const handleCreate = useCallback(() => {
    historyUtils.push(RouteName.EMPLOYEE_CREATE); //+
  }, []);
  const handleDataSave = useCallback(
    (data, type) => {
      if (type == "CREATE") {
        dispatch(actionCreateContact(data));
      } else {
        dispatch(actionUpdateContact(data));
      }
      setEditData(null);
    },
    [setEditData]
  );

  const queryFilter = useCallback(
    (key, value) => {
      console.log("_queryFilter", key, value);
      dispatch(
        actionFetchContact(1, sortingData, {
          query: key == "SEARCH_TEXT" ? value : query,
          query_data: key == "FILTER_DATA" ? renderStatus(value) : renderStatus(queryData),
        })
      );
      // dispatch(actionFetchEmployee(1, sortingData))
    },
    [sortingData, query, queryData,renderStatus]
  );

  const handleFilterDataChange = useCallback(
    (value) => {
      const statusObject = value?.find((item) => item.name === "status");
      const statusValue = statusObject ? statusObject.value : null;
      setStatus(statusValue);
      queryFilter("FILTER_DATA", value);
    },
    [queryFilter, status]
  );

  const handleSearchValueChange = useCallback(
    (value) => {
      console.log("_handleSearchValueChange", value);
      queryFilter("SEARCH_TEXT", value);
    },
    [queryFilter]
  );

  const handleSortOrderChange = useCallback(
    (row, order) => {
      console.log(`handleSortOrderChange key:${row} order: ${order}`);
      // dispatch(actionSetPageContactRequests(1));
      dispatch(
        actionFetchContact(
          1,
          { row, order },
          {
            query: query,
            query_data: renderStatus(queryData),
          }
        )
      );
    },
    [query, queryData,renderStatus]
  );

  const handleRowSize = (page) => {
    console.log(page);
  };

  const configFilter = useMemo(() => {
    return [
      {
        label: "Location",
        name: "location_id",
        type: "selectObject",
        custom: { extract: { id: "id", title: "name" } },
        fields: listData?.LOCATIONS,
      },
      {
        label: "Grade",
        name: "grade_id",
        type: "selectObject",
        custom: { extract: { id: "id", title: "label" } },
        fields: listData?.GRADES,
      },
      {
        label: "Department",
        name: "department_id",
        type: "selectObject",
        custom: { extract: { id: "id", title: "name" } },
        fields: listData?.DEPARTMENTS,
      },
    ];
  }, [listData]);

  return {
    handlePageChange,
    // handleCellClick,
    handleDataSave,
    handleFilterDataChange,
    handleSearchValueChange,
    // handlePreviousPageClick,
    // handleNextPageClick,
    handleRowSize,
    handleSortOrderChange,
    isCalling,
    editData,
    configFilter,
    listData,
  };
};

export default useContactList;

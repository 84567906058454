export default {
  positive: {
    active: require("../../../assets/img/form_img/positivity_admin.png"),
    inactive: require("../../../assets/img/form_img/positivity_grey.png"),
  },
  problem: {
    active: require("../../../assets/img/form_img/problemsolver_admin.png"),
    inactive: require("../../../assets/img/form_img/problem_grey.png"),
  },
  champ: {
    active: require("../../../assets/img/form_img/teamwork_selected.png"),
    inactive: require("../../../assets/img/form_img/teamwork_grey.png"),
  },
  extra_mile: {
    active: require("../../../assets/img/form_img/extra_mile_admin.png"),
    inactive: require("../../../assets/img/form_img/extra_mile_grey.png"),
  },
  legend: {
    active: require("../../../assets/img/form_img/leadership_admin.png"),
    inactive: require("../../../assets/img/form_img/leadership_grey.png"),
  },
};

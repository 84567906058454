import { ButtonBase } from "@material-ui/core";
import React from "react";
import historyUtils from "../../../libs/history.utils";
import RouteName from "../../../routes/Route.name";
import styles from "./Style.module.css";
import { useLocation } from "react-router";
function SuccessOnboarding() {
  const location = useLocation();
  const isDrafted = location?.state?.isDrafted;
  
  return (
    <div className={styles.FormSubmitWrapper}>
      <div className={styles.formSubmitContainer}>
        <div className={styles.formSubmitImageWrapper}>
          <img
            src={require("../../../assets/img/ic_successfully submited.png")}
            className={styles.sky}
          />
        </div>
        <div className={styles.formSubmitBottomWrapper}>
          <div className={styles.submitHeader}>
            <p>
              {isDrafted
                ? "Draft saved successfully"
                : "Documents Successfully Submitted!"}
            </p>
          </div>
          <div className={styles.submitDescription}>
            <p style={{textAlign:"center"}}>
              {isDrafted
                ? "If you want to continue with the submission access link through email received"
                : "Thanks for your submission."}
              <br />
              {/* You can now close this window */}
            </p>
          </div>
          <div>
            <div className={styles.btnContainer}>
              <div className={styles.btnCont1}>
                <ButtonBase
                  type={"button"}
                  onClick={() => {
                    historyUtils.push(RouteName.ONBOARDING_LOGIN);
                  }}
                  className={styles.createBtn}
                >
                  Close
                </ButtonBase>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessOnboarding;

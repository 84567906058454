import {
  ButtonBase,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import React from "react";
import CustomTextField from "../../../components/FormFields/TextField/TextField.component";
import styles from "./Style.module.css";
import useAppreciationLogin from "./AppreciationLoginHook";
import SnackbarComponent from "../../../components/Snackbar.component";
import { Visibility, VisibilityOff } from "@material-ui/icons";

function AppreciationLogin() {
  const {
    changeTextData,
    errorData,
    form,
    handleSubmit,
    isSubmitting,
    onBlurHandler,
    showPasswordCurrent,
    setShowPasswordCurrent,
  } = useAppreciationLogin({});
  return (
    <div className={styles.employeeLoginWrapper}>
      <div className={styles.signContainer}>
        <div className={styles.logoImg}>
          <img
            src={require("../../../assets/img/form_img/skytech_login_logo@2x.png")}
            className={styles.sky}
          />
        </div>
        <div className={styles.loginSignupText}>
          <h1 className={styles.headingText}>Appreciation Login</h1>
          <div className={styles.newLine} />
          <p className={styles.newLinetitle}>
            Enter your email ID and Password .
          </p>
        </div>
        <br />
        <div>
          <div>
            <CustomTextField
              isError={errorData?.emp_id}
              errorText={errorData?.emp_id}
              label={"Employee ID"}
              value={form?.emp_id}
              onTextChange={(text) => {
                changeTextData(text, "emp_id");
              }}
              onBlur={() => {
                onBlurHandler("emp_id");
              }}
            />
          </div>
          <br />
          <div>
            <CustomTextField
              type={showPasswordCurrent ? "text" : "password"}
              label={"Password"}
              value={form?.password}
              onTextChange={(text) => {
                changeTextData(text, "password");
              }}
              isError={errorData?.password}
              errorText={errorData?.password}
              onBlur={() => {
                onBlurHandler("password");
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPasswordCurrent(!showPasswordCurrent)
                      }
                    >
                      {showPasswordCurrent ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className={styles.btnWrap}>
          <ButtonBase
            disabled={isSubmitting}
            onClick={handleSubmit}
            className={styles.login}
          >
            {isSubmitting ? (
              <CircularProgress color="success" size="20px" />
            ) : (
              "Submit"
            )}
          </ButtonBase>
        </div>
      </div>
      <SnackbarComponent />
    </div>
  );
}

export default AppreciationLogin;

import {
  ButtonBase,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import CustomTextField from "../../../components/FormFields/TextField/TextField.component";
import styles from "./Style.module.css";
import useOnboardingLogin from "./OnboardingLoginHook";
import SnackbarComponent from "../../../components/Snackbar.component";

function OnboardingLogin() {
  const {
    changeTextData,
    errorData,
    form,
    handleSubmit,
    isSubmitting,
    onBlurHandler,
  } = useOnboardingLogin({});
  return (
    <div className={styles.employeeLoginWrapper}>
      <div className={styles.signContainer}>
        <div className={styles.logoImg}>
          <img
            src={require("../../../assets/img/login logo@2x.png")}
            className={styles.sky}
          />
        </div>
        <h1 className={styles.headingText}>Post Onboarding Documents</h1>
        <div className={styles.loginSignupText}>
          <h1 className={styles.headingText}>Submission Login</h1>
          <div className={styles.newLine} />
          <p className={styles.newLinetitle}>
            Enter your Email ID and the 4 digit unique access code that you have
            received
          </p>
        </div>
        <br />
        <div>
          <div>
            <CustomTextField
              isError={errorData?.email}
              errorText={errorData?.email}
              label={"Email ID"}
              value={form?.email}
              onTextChange={(text) => {
                changeTextData(text, "email");
              }}
              onBlur={() => {
                onBlurHandler("email");
              }}
            />
          </div>
          <br />
          <div>
            <CustomTextField
              type="number"
              label={"4 Digit Code"}
              value={form?.code}
              onTextChange={(text) => {
                changeTextData(text, "code");
              }}
              isError={errorData?.code}
              errorText={errorData?.code}
              onBlur={() => {
                onBlurHandler("code");
              }}
            />
          </div>
        </div>
        <div className={styles.btnWrap}>
          <ButtonBase
            disabled={isSubmitting}
            onClick={handleSubmit}
            className={styles.login}
          >
            {isSubmitting ? (
              <CircularProgress color="success" size="20px" />
            ) : (
              "Submit"
            )}
          </ButtonBase>
        </div>
      </div>
      <SnackbarComponent />
    </div>
  );
}

export default OnboardingLogin;
